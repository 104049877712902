
import { mapState } from 'vuex';
import Loader from '../Loader';
import PhotoOverlay from './PhotoOverlay';

export default {
  name: 'PhotoZoom',
  components: {
    Loader,
    PhotoOverlay,
  },
  data: () => ({
    isLoaded: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoIssuesResult',
    ]),
    ...mapState('inspections__new/photos', [
      'currentIssue',
      'cursorOffsetX',
      'cursorOffsetY',
      'isDrawingActive',
      'isCursorNeeded',
      'isNotatingActive',
    ]),
    photo() {
      if (!this.currentPhoto) {
        return null;
      }
      return this.currentPhoto.image_processed || this.currentPhoto.image_original;
    },
    width() {
      if (!this.currentPhoto) {
        return 1;
      }
      return this.currentPhoto.image_width;
    },
    height() {
      if (!this.currentPhoto) {
        return 1;
      }
      return this.currentPhoto.image_height;
    },
    style() {
      if (!this.isLoaded) {
        return null;
      }
      const additionalOffset = (this.$refs.canvas?.offsetWidth || 1) / 2;
      const transform = `translateX(${-1 * this.cursorOffsetX * this.width + additionalOffset}px) translateY(${-1 * this.cursorOffsetY * this.height + additionalOffset}px)`;
      return { transform };
    },
  },
  methods: {
    setLoaded() {
      this.isLoaded = true;
    },
  },
  watch: {
    currentPhoto() {
      this.isLoaded = false;
    },
    currentIssue: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$store.dispatch('inspections__new/photos/centerOnActivePoint');
        }
      },
    }
  },
}
