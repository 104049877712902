
import { mapState } from 'vuex';
import * as Cesium from 'cesium';
import Loader from '../Loader';

export default {
  name: 'PhotoInfo',
  components: {
    Loader,
  },
  data: () => ({
    isMapLoaded: false,
    isInfoLoaded: false,
    info: null,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',
      'currentInspection',
    ]),
    ...mapState('assets__new', [
      'getAssetsItem',
    ]),
  },
  methods: {
    getInfo() {
      const requestOtherData = assetData => {
        this.info = { asset: assetData };
        // this.$store.dispatch('organizations/getOrganizationsItem', {
        //   id: assetData.organization,
        //   onSuccess: organization => this.info = { ...this.info, organization },
        // });
        this.$store.dispatch('users/getUsersItem', {
          id: assetData.owner,
          onSuccess: owner => this.info = { ...this.info, owner },
          onError: () => this.info = {
            ...this.info,
            owner: { first_name: 'Unknown', last_name: '' },
          },
        });
        // this.$store.dispatch('inspections__new/getInspectionsItem', {
        //   id: assetData.inspection,
        //   onSuccess: inspection => this.info = { ...this.info, inspection },
        // });
      };
      this.$store.dispatch('assets__new/getAssetsItem', { id: this.currentPhoto.asset, onSuccess: requestOtherData });
    },
    setMap() {
      const mapScriptElementId = 'map-script';
      if (document.getElementById(mapScriptElementId)) {
        this.isMapLoaded = true;
        this.$nextTick(this.initMap);
        return;
      }
      window.gMapsCallback = () => {
        this.initMap();
        this.isMapLoaded = true;
      }
      const script_tag = document.createElement('script');
      script_tag.setAttribute('id', mapScriptElementId);
      script_tag.setAttribute('type', 'text/javascript');
      script_tag.setAttribute('async', '');
      script_tag.setAttribute('defer', '');
      script_tag.setAttribute('src','https://maps.googleapis.com/maps/api/js?key=AIzaSyA0eQ1_USjZ7u66zjkjKVqPCJig5VYKpiM&callback=gMapsCallback');
      (document.getElementsByTagName('body')[0] || document.documentElement).appendChild(script_tag);
    },
    initMap() {
      let latitude = this.currentPhoto.latitude;
      let longitude = this.currentPhoto.longitude;
      if (latitude > 100 || latitude < 0) {
        const position = new Cesium.Cartesian3(longitude, latitude, this.currentPhoto.altitude);
        const cartographic = Cesium.Cartographic.fromCartesian(position);
        latitude = Cesium.Math.toDegrees(cartographic.latitude);
        longitude = Cesium.Math.toDegrees(cartographic.longitude);
      }
      const position = { lat: latitude, lng: longitude };
      const map = new google.maps.Map(this.$refs.map, {
        zoom: 18,
        mapTypeId: 'satellite',
        center: position,
      });
      new google.maps.Marker({ position, map });
    },
  },
  beforeMount() {
    this.getInfo();
    this.setMap();
  },
  watch: {
    info(newVal) {
      if (newVal && newVal.owner) {
        this.isInfoLoaded = true;
      }
    },
  },
}
