
import { mapState } from 'vuex';
import { v4 as uuid } from 'uuid';
import PhotoZoom from './PhotoZoom';

export default {
  name: 'PhotoIssuesAdd',
  components: {
    PhotoZoom,
  },
  data: () => ({
    type: '',
    comment: '',
    positionX: null,
    positionY: null,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentInspection',
      'currentPhoto',

      'getPhotoIssuesResult',
      'getPhotoIssuesIsLoading',
      'getPhotoIssuesError',
    ]),
    ...mapState('inspections__new/photos', [
      'currentIssue',
      'isDrawingActive',
      'drawingPolygonPoints',
      'drawingPolygonCenter',
      'cursorOffsetX',
      'cursorOffsetY',
    ]),
    isLoading() {
      return  this.getPhotoIssuesIsLoading || this.addPhotoIssuesIsLoading || this.removePhotoIssuesIsLoading;
    },
  },
  methods: {
    drawHandler() {
      this.$store.state.inspections__new.photos.cursorOffsetX = 0.5;
      this.$store.state.inspections__new.photos.cursorOffsetY = 0.5;
      this.$store.state.inspections__new.photos.isDrawingActive = true;
    },
    onSubmit() {
      if (!this.type) {
        return;
      }
      const payload = new FormData();
      payload.append('photo', this.currentPhoto.id);
      payload.append('inspection', this.currentInspection.id);
      payload.append('issue_type', this.type);
      payload.append('status', 'suggested');
      payload.append('reference', uuid());
      if (this.comment) {
        payload.append('content', this.comment);
      }
      if (this.drawingPolygonPoints.length > 0) {
        payload.append('vertices_center', JSON.stringify(this.drawingPolygonCenter));
        payload.append('vertices', JSON.stringify(this.drawingPolygonPoints));
      }
      this.$store.dispatch('inspections__new/addPhotoIssues', payload);
    },
    backHandler() {
      this.$emit('close-add-form');
    },
    removePoint(index) {
      this.$store.dispatch('inspections__new/photos/removePolygonPoint', index);
    },
    clearPoints() {
      this.$store.state.inspections__new.photos.isDrawingActive = false;
      this.$store.state.inspections__new.photos.drawingPolygonPoints = [];
    },
  },
  beforeUnmount() {
    this.clearPoints();
  },
  watch: {
    cursorOffsetX(newVal) {
      if (this.isDrawingActive) {
        this.positionX = Math.floor(newVal * this.currentPhoto.image_width);
      }
    },
    cursorOffsetY(newVal) {
      if (this.isDrawingActive) {
        this.positionY = Math.floor(newVal * this.currentPhoto.image_height);
      }
    },
  },
}
