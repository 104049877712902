
import { mapState } from 'vuex';
import Loader from '../Loader';
import PhotoImage from './PhotoImage';
import PhotoInfo from './PhotoInfo';
import PhotoZoom from './PhotoZoom';
import PhotoNotes from './PhotoNotes';
import PhotoIssues from './PhotoIssues';
import PhotoToolbar from './PhotoToolbar';

export default {
  name: 'Photo',
  components: {
    Loader,
    PhotoImage,
    PhotoToolbar,
    PhotoInfo,
    PhotoZoom,
    PhotoNotes,
    PhotoIssues,
  },
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoIssuesResult',

      'getPhotoNotesResult',
    ]),
    ...mapState('inspections__new/photos', [
      'currentToolCode',
    ]),
  },
  watch: {
    currentPhoto: {
      immidiate: true,
      handler(newVal) {
        if (!newVal) {
          return;
        }
        if (!this.getPhotoIssuesResult || this.getPhotoIssuesResult.length === 0 || this.getPhotoIssuesResult[0].photo !== newVal.id) {
          this.$store.dispatch('inspections__new/getPhotoIssues', { id: newVal.id });
        }
        if (!this.getPhotoNotesResult || this.getPhotoNotesResult.length === 0 || this.getPhotoNotesResult[0].photo !== newVal.id) {
          this.$store.dispatch('inspections__new/getPhotoNotes', { id: newVal.id });
        }
      },
    },
  }
};
