
import { mapState } from 'vuex';
import PhotoZoom from './PhotoZoom';

export default {
  name: 'PhotoIssuesCurent',
  components: {
    PhotoZoom,
  },
  data: () => ({
    type: '',
    comment: '',
    isConfirmationNeeded: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoIssuesResult',
      'getPhotoIssuesIsLoading',
      'getPhotoIssuesError',
    ]),
    ...mapState('inspections__new/photos', [
      'currentIssue',
    ]),
    isLoading() {
      return  this.getPhotoIssuesIsLoading || this.addPhotoIssuesIsLoading || this.removePhotoIssuesIsLoading;
    },
  },
  methods: {
    getFormData() {
      const payload = new FormData();
      Object.keys(this.currentIssue).forEach(x => {
        if (x === 'vertices' || x === 'vertices_center') {
          payload.append(x, JSON.stringify(this.currentIssue[x]) || 'null');
        } else {
          payload.append(x, this.currentIssue[x] || 'null');
        }
      });
      return payload;
    },
    onSubmit() {
      const payload = this.getFormData();
      payload.set('status', 'accepted');
      this.$store.dispatch('inspections__new/updatePhotoIssues', payload);
    },
    rejectHandler() {
      const payload = this.getFormData();
      payload.set('status', 'rejected');
      this.$store.dispatch('inspections__new/updatePhotoIssues', payload);
    },
    removeHandler() {
      this.isConfirmationNeeded = true;
    },
    cancel() {
      this.isConfirmationNeeded = false;
    },
    remove() {
      this.$store.dispatch('inspections__new/removePhotoIssues', { id: this.currentIssue.id });
      this.isConfirmationNeeded = false;
    },
    backHandler() {
      this.$store.commit('inspections__new/photos/setCurrentIssue', null);
    },
  },
  beforeUnmount() {
    this.$store.commit('inspections__new/photos/setCurrentIssue', null);
  },
  watch: {
    currentIssue: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.type = newVal.issue_type;
          this.comment = newVal.content === 'None' ? '' : newVal.content;
        } else {
          this.type = '';
          this.comment = '';
        }
      }
    }
  },
}
