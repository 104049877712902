
import { mapState } from 'vuex';
import Loader from '../Loader';
import PhotoIssuesAdd from './PhotoIssuesAdd';
import PhotoIssuesCurrent from './PhotoIssuesCurrent';
import PhotoIssuesList from './PhotoIssuesList';

export default {
  name: 'PhotoIssues',
  components: {
    Loader,
    PhotoIssuesAdd,
    PhotoIssuesCurrent,
    PhotoIssuesList,
  },
  data: () => ({
    isAddFormActive: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoIssuesResult',
      'getPhotoIssuesIsLoading',
      'getPhotoIssuesError',

      'addPhotoIssuesResult',
      'addPhotoIssuesIsLoading',
      'addPhotoIssuesError',

      'updatePhotoIssuesResult',
      'updatePhotoIssuesIsLoading',
      'updatePhotoIssuesError',

      'removePhotoIssuesResult',
      'removePhotoIssuesIsLoading',
      'removePhotoIssuesError',
    ]),
    ...mapState('inspections__new/photos', [
      'currentIssue',
    ]),
    isLoading() {
      return  this.getPhotoIssuesIsLoading || this.addPhotoIssuesIsLoading || this.updatePhotoIssuesIsLoading || this.removePhotoIssuesIsLoading;
    },
  },
  methods: {
    setIssue(issue) {
      this.highlightIssue(null);
      this.$store.commit('inspections__new/photos/setCurrentIssue', issue);
    },
    highlightIssue(issue) {
      this.$store.commit('inspections__new/photos/setHighlightedIssue', issue);
    },
    closeAddForm() {
      this.isAddFormActive = false;
    },
    openAddForm() {
      this.isAddFormActive = true;
    },
  },
  watch: {
    addPhotoIssuesIsLoading(newVal) {
      if (!newVal && this.addPhotoIssuesResult) {
        this.closeAddForm();
        this.$store.dispatch('inspections__new/getPhotoIssues', { id: this.currentPhoto.id });
      }
    },
    updatePhotoIssuesIsLoading(newVal) {
      if (!newVal && this.updatePhotoIssuesResult) {
        this.setIssue(this.updatePhotoIssuesResult);
        this.$store.dispatch('inspections__new/getPhotoIssues', { id: this.currentPhoto.id });
      }
    },
    removePhotoIssuesIsLoading(newVal) {
      if (!newVal && this.removePhotoIssuesResult) {
        this.setIssue(null);
        this.$store.dispatch('inspections__new/getPhotoIssues', { id: this.currentPhoto.id });
      }
    },
  },
}
