
import { mapState } from 'vuex';
import Loader from '../Loader';

export default {
  name: 'PhotoIssues',
  components: {
    Loader,
  },
  data: () => ({
    isAddFormActive: false,
  }),
  computed: {
    ...mapState('inspections__new', [
      'currentPhoto',

      'getPhotoIssuesResult',
      'getPhotoIssuesIsLoading',
      'getPhotoIssuesError',
    ]),
    ...mapState('inspections__new/photos', [
      'highlightedIssue',
    ]),
  },
  methods: {
    setIssue(issue) {
      this.highlightIssue(null);
      this.$store.commit('inspections__new/photos/setCurrentIssue', issue);
    },
    highlightIssue(issue) {
      this.$store.commit('inspections__new/photos/setHighlightedIssue', issue);
    },
    openAddForm() {
      this.$emit('open-add-form');
    },
  },
}
